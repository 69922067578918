@use "../abstract/responsive" as rwd;


// Communication section
.horizontalNewsListing {
  .boxListSection {
    margin-top: 20px;

    @include rwd.breakpoint(mobile) {
      margin-top: 10px;
    }

    @include rwd.breakpoint(phonesDown) {
      margin-left: -5px;
      margin-right: -5px;
    }
  }

  .contentSection {
    position: relative;
    
    @include rwd.breakpoint(tabletmobile) {
      .horizontalBox {
        order: 1;
      }

      .rightBox {
        order: 3;
      }

      .listContent {
        order: 2;
      }
    }

    .listContent {
      .readMore {
        &:after {
          @include rwd.breakpoint(tabletmobile) {
            top: 40%;
            right: 25px;
          }
        }

        &.arrow {
          &:after {
            @include rwd.breakpoint(tabletmobile) {
              transform: rotate(0deg);
            }
          }
        }
      }
    }

    .rightBox {
      @include rwd.breakpoint(tablet) {
        display: none;
      }

      li:nth-of-type(3) {
        @include rwd.breakpoint(smallUp) {
          .titleLink {
            border-bottom: none;
          }
        }
      }
    }
  }
  
  .aside {
    @include rwd.breakpoint(mobile) {
      margin-top: 20px;
    }
  }
}